<template>
  <div>
    <span v-if="colunaEditavel">
      <v-edit-dialog
        :return-value.sync="dadoNovo"
        large
        persistent
        cancel-text="Cancelar"
        save-text="Salvar"
        @save="save"
      >
        <div>{{ dadoAtual | treatDinamic(tipoDado) }}</div>
        <template v-slot:input>
          <div class="mt-4 text-h6">
            {{ coluna.toLowerCase() }} #{{ id | parseNumberToIntegerBR }}
          </div>
          <div v-if="['smallint', 'bigint', 'integer', 'inteiro'].includes(tipoDado)">
            <v-text-field
              v-model="dadoNovo"
              type="number"
              :step="1"
              autofocus
            />
          </div>
          <div v-else-if="['decimal', 'numeric'].includes(tipoDado)">
            <v-text-field
              v-model="dadoNovo"
              type="number"
              :step="0.01"
              autofocus
            />
          </div>
          <div v-else-if="tipoDado === 'data'">
            <input-date
              :label="null"
              :selectedDate="dadoAtual"
              :rules="{ required: true, min: 10 }"
              @dataSelected="(data) => (dadoNovo = data)"
            />
          </div>
          <div v-else>
            <v-text-field
              v-model="dadoNovo"
              single-line
              counter
              autofocus
            />
          </div>
        </template>
      </v-edit-dialog>
    </span>
    <span v-else @click="notEditableClick">
      {{ dadoAtual | treatDinamic(tipoDado) }}
    </span>
  </div>
</template>

<script>
import AtualizacoesTabelasService from '@/services/AtualizacoesTabelasService';

export default {
  name: 'EditarDadoTabela',
  components: {
    InputDate: () => import('@/components/general/InputDate')
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    tabela: {
      type: String,
      required: true
    },
    coluna: {
      type: String,
      required: true
    },
    dadoInicial: {
      required: true
    },
    tipoDado: {
      type: String,
      required: true
    },
    edicaoHabilitada: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    dadoAtual: null,
    dadoNovo: null,
    colunasNaoEditaveis: [
      'gid'
    ]
  }),
  mounted() {
    this.dadoAtual = this.dadoInicial;
    this.dadoNovo = this.dadoInicial;
  },
  computed: {
    colunaEditavel() {
      return (
        this.edicaoHabilitada && !this.colunasNaoEditaveis.includes(this.coluna)
      );
    }
  },
  methods: {
    notEditableClick() {
      this.$emit('notEditableClick', {
        coluna: this.coluna,
      });
    },

    save() {
      if (this.dadoAtual === this.dadoNovo) {
        return;
      }

      AtualizacoesTabelasService.salvarDado(
        this.tabela,
        this.id,
        this.coluna,
        this.dadoNovo,
        this.tipoDado
      )
        .then(() => {
          this.dadoAtual = this.dadoNovo;
          this.$toast.success(
            `Dado da coluna ${this.coluna}, na linha #${this.id}, salvo com sucesso.`,
            '',
            { position: 'topRight' }
          );
        })
        .catch((error) => {
          this.dadoNovo = this.dadoAtual; 
          this.$toast.error(
            `Ocorreu um erro ao salvar o dado da coluna ${this.coluna}, na linha #${this.id}.`,
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        });
    }
  }
};
</script>
